

const blocked_dates = [
    {"day": "8", "month": "0"},
    {"day": "15", "month": "0"},
    {"day": "22", "month": "0"},
    {"day": "29", "month": "0"},
    {"day": "5", "month": "1"},
    {"day": "12", "month": "1"},
    {"day": "19", "month": "1"},
    {"day": "26", "month": "1"},
    {"day": "4", "month": "2"},
    {"day": "11", "month": "2"},
    {"day": "18", "month": "2"},
    {"day": "25", "month": "2"},
    {"day": "16", "month": "0"},
    {"day": "23", "month": "0"},
    {"day": "30", "month": "0"},
    {"day": "6", "month": "1"},
    {"day": "13", "month": "1"},
    {"day": "20", "month": "1"},
    {"day": "27", "month": "1"},
    {"day": "5", "month": "2"},
]

const Calendar = ({ month, year }) => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);
  const lastDayOfPreviousMonth = new Date(year, month, 0);

  const weeks = [];
  let week = new Array(7).fill(null);
  let dayCounter = 1 - firstDayOfMonth.getDay();

  for (let row = 0; row < 6; row++) {
    week = new Array(7).fill(null);
    for (let col = 0; col < 7; col++) {
      if (dayCounter >= 1 && dayCounter <= lastDayOfMonth.getDate()){
        if (dayCounter < 1) {
            week[col] = lastDayOfPreviousMonth.getDate() + dayCounter;
          } else if (dayCounter > lastDayOfMonth.getDate()) {
            week[col] = dayCounter - lastDayOfMonth.getDate();
          } else {
            week[col] = dayCounter;
          }
      }
      dayCounter++;
    }
    weeks.push(week);
  }


  const isBlockedColumn = (colIndex) => {
    return blocked_dates.some(
      (blockedDate) =>
        parseInt(blockedDate.month) === month &&
        parseInt(blockedDate.day) === colIndex
    );
  };

  return (
    <div className="calendar">
      <h2 className="text-center text-white text-lg font-semibold mb-2">
        {firstDayOfMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
      </h2>
      <div className="grid grid-cols-7">
        {daysOfWeek.map((day) => (
          <div key={day} className="text-center font-semibold bg-cust-orange py-2">
            {day}
          </div>
        ))}
        {weeks.map((week, rowIndex) =>
          week.map((day, colIndex) => (
            <div
              key={rowIndex * 7 + colIndex}
              className={`text-center py-2 ${
                isBlockedColumn(day) ? 'bg-cust-orange-light' : // apply a red background to blocked columns
                'bg-cust-orange-xxlight'
                // colIndex === 1 ? 'bg-cust-orange-xlight' : 'bg-cust-orange-xxlight'
              }`}
            >
              {day !== null ? day : '-'}
            </div>
          ))
        )}
      </div>
    </div>
  );
};


const LeagueSchedule = () => {
    return (
        <div class="bg-cust-gray-dark lg:px-8">
            <h1 class='flex font-medium justify-center text-2xl font-semibold text-center capitalize lg:text-3xl text-white pt-8'>Schedule</h1>
            <div className="px-6  md:px-32 pb-4 pt-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                    <Calendar month={0} year={2024} />
                    <Calendar month={1} year={2024} />
                    <Calendar month={2} year={2024} />
                </div>
            </div>
            <p class="text-white pb-6 px-6 md:px-48">The tournament will commence on January 8th and conclude on March 25th. Games scheduled for Mondays will begin at 9:00 PM, while those on Tuesdays will start at 8:00 PM.</p>
        </div>

    );

};


export default LeagueSchedule;
