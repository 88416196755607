import UiFooter from '../components/custom_footer';
import NavBar from '../components/navbar'
import LeaderBoard from '../components/leaderboard'
import Fixtures from '../components/fixtures'
import LeagueSchedule from '../components/schedule'
import Grounds from '../components/grounds'
import Faqs from '../components/faq'

export default function Schedule() {
    return (
        <div>
            <NavBar></NavBar>
            {/* <div className="bg-cust-gray">
                <LeaderBoard></LeaderBoard>
            </div>
            <Fixtures></Fixtures> */}
            <Grounds></Grounds>

            <LeagueSchedule></LeagueSchedule>

            <Faqs></Faqs>
            <UiFooter></UiFooter>
        </div>
    )
}
