
import UiFooter from '../components/custom_footer';
import NavBar from '../components/navbar'
import Carousel from '../components/carousel';
import Album from '../components/albums';

const image_paths = [
    "images/6.jpg",
    "images/1.jpg",
    "images/2.jpg",
    "images/4.jpg",
    "images/3.jpg",
    "images/5.jpg",
    "images/7.jpg",
    "images/8.jpg",
    "images/9.jpg",
    "images/10.jpg",
    "images/11.jpg",
    "images/12.jpg",
    "images/12.jpg",
    "images/14.jpg",
    "images/15.jpg",
    "images/16.jpg",
    "images/17.jpg",
    "images/18.jpg",
    "images/19.jpg",
    "images/20.jpg",
    "images/21.jpg",
    "images/22.jpg",
    "images/4.jpg",
    "images/12.jpg",
]


export default function Gallery() {
    return (
        <div>

    <NavBar></NavBar>
    <Album images={image_paths} title={"2023 Winter League"}></Album>
    {/* <Carousel images={image_paths}></Carousel> */}

    <UiFooter></UiFooter>
</div>

    )
}
