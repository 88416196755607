


const Grounds = () => {
    return (
        <div class="bg-cust-gray-light px-6 lg:px-8">
            <h1 class='flex font-medium justify-center text-2xl font-semibold text-center capitalize lg:text-3xl text-white pt-8'>Indoor Fields</h1>
            <div class="grid grid-cols-1 sm:grid-cols-4 gap-12 py-2 sm:py-8">
                <div></div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src="http://files.leagueathletics.com/Images/Club/2074/PotashTurfField4.jpeg" alt=""></img>
                    <p class="flex text-white justify-center text-center">Tyngsborough</p>
                </div>
                <div>
                    <img class=" h-auto max-w-full rounded-lg" src="https://assets-global.website-files.com/6287a60491203231163514f3/644835969c61c8a1d01332e1_Turf%20Use-57-p-1600.jpg" alt=""></img>
                    <p class="flex text-white justify-center text-center">Westford</p>
                </div>
                <div></div>
            </div>
        </div>
    )
};


export default Grounds;
