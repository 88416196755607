// import logo from './assets/logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Home from './pages/home'
import Credits from './pages/credits';
import TeamRegistration from './pages/team_registration';
import PlayerRegistration from './pages/player_registration';
import Schedule from './pages/schedule';
import Gallery from './pages/gallery';
import { PlayerRegistrationForm } from './components/registration';

function Redirect({ to }) {
  let navigate = useNavigate();
  useEffect(() => {
    window.location.href = to;
  }, [to, navigate]);

  return null;
}

function App() {
  return (
    <Router>
      <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/credits' element={<Credits />} />
          <Route path='/team-registration' element={<TeamRegistration />} />
          <Route path='/player-registration' element={<PlayerRegistration />} />
          <Route path='/schedule' element={<Schedule />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/waiver' element={<Redirect to="https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhBtIybBJLrs8I_GqhnRoojItVDXyzi4oUoDar5d_qhLrjSed3b7KGBkUJ0AxLpda_k*" />} />
          <Route path='/registration' element={<PlayerRegistrationForm />} />

      </Routes>
    </Router>
  );
}
export default App;
