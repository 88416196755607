// import LogoComponent from './logo';
import Pricing from './pricing';
import Features from "./features";
import SubscribeComponent from "./subscribe";

// import { ReactComponent as Logo } from './../assets/logo.svg'


export default function Main() {
  return (
    <div className="overflow-hidden bg-gradient-to-r from-cust-gray via-cust-gray-light to-cust-gray">
      <div class="container justify-center flex flex-col px-6 py-4 mx-auto space-x-2 space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center lg:space-x-16">
        <div class="flex items-center justify-center max-w-md lg:w-1/2">
            {/* <LogoComponent></LogoComponent> */}
            <img src="logo_mdpi.png" alt="" class="transform scale-75 lg:scale-100" ></img>
        </div>
        <div class="flex flex-col items-center max-w-xlg lg:flex-row lg:w-1/2">
            <div class="max-w-lg lg:mx-12 lg:order-2">
                <h1 class="font-['Helvetica'] text-2xl font-semibold tracking-wide text-white lg:text-4xl">Super Cricket League</h1>
                <p class="mt-4 text-gray-300">Join Super Cricket League (Boston), where our collective passion for cricket unites us! Engage in thrilling matches, build lasting relationships, and enhance your fitness, all while immersing yourself in the exhilarating world of cricket.</p>
                <p class="mt-4 text-gray-300">2024 season starts Jan 8th. 2 exciting indoor venues in Westford and Tyngsborough.</p>
            </div>
        </div>
    </div>

      <Features></Features>
      <div class="bg-cust-gray py-12 sm:py-12">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          <div class="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt class="text-base leading-7 text-white">Minimum games per team</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">7+</dd>
          </div>
          <div class="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt class="text-base leading-7 text-white">Balls per innings</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">50</dd>
          </div>
          <div class="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt class="text-base leading-7 text-white">Players with one super-sub</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">8</dd>
          </div>
        </dl>
      </div>
    </div>
      <Pricing></Pricing>
      <SubscribeComponent></SubscribeComponent>

    </div>
  )
}
