import React from 'react';


const Album = ({ images, title }) => {
  return (
    <div class="bg-cust-gray">
    <h1 class='flex font-medium text-white justify-center text-center mb-4 font-semibold text-white pt-8'>{title}</h1>
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="overflow-hidden rounded-lg bg-gray-100"
          >
            <img
              src={image}
              alt={`Gallery ${index}`}
              className="object-cover w-full h-full"
            />
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Album;
